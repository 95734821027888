.rdw-editor-wrapper {
  max-height: calc(100vh - 420px);
  min-height: calc(100vh - 420px);
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}
body {
  line-height: 1.43;
}

a {
  text-decoration: none;
  color: none;
}

* {
  /* font-family: 'Mulish', Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: CircularStd;
  font-style: normal;
  font-weight: 400;
  src: url(fonts/CircularStd-Regular.8dd9b00.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: CircularStd;
  font-style: normal;
  font-weight: 500;
  src: url(fonts/CircularStd-Medium.f9f2be1.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: CircularStd;
  font-style: normal;
  font-weight: 700;
  src: url(fonts/CircularStd-Bold.ce7af6d.ttf) format('truetype');
  font-display: swap;
}

.reactMarkDownCriteria p {
  margin-block-start:  0;
  margin-block-end: 0;
}


.reactMarkDownCriteria ul {
  margin-block-start:  0;
  margin-block-end: 0;
}

.MuiTable-stickyHeader th {
  border-top: 1px solid #DDE2E4;
}

.MuiTable-stickyHeader th:first-child,
.MuiTable-stickyHeader td:first-child {
  border-left: 1px solid #DDE2E4;
}

.MuiTable-stickyHeader th:last-child,
.MuiTable-stickyHeader td:last-child {
  border-right: 1px solid #DDE2E4;
}
